import {
  confirmFulfillmentOrder,
  confirmOrder,
  confirmPayme,
  getOrder,
  payme,
  submitCart,
  updateCart
} from '@/src/apis'
import { banking, qrCode } from '@/src/assets'
import { Footer, Header } from '@/src/components'
import { APP_INFO, CARRIER_MODEL, CHANNEL, PAYMENT_TYPE_MODEL } from '@/src/constants'
import { useApp, useUser } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { formatMoney, useLocalStorage } from '@/src/utils'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const PaymentMethodDetail: React.FC = () => {
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  const { cart, onLoading, onSetOrder, RFID } = useApp()
  const { phone } = useUser()
  const { methodId } = useParams()
  const navigate = useNavigate()

  const onUpdateInfoCart = async () => {
    const arrEPCs = []

    for (let i = 0; i < RFID.length; i += 3) {
      const epcs = RFID.slice(i, i + 3).map((item: any) => item.tag)
      arrEPCs.push({
        key: `x-haravan-epcs-0${Math.floor(i / 3) + 1}`,
        value: epcs.toString()
      })
    }

    if (!arrEPCs) return

    let payload = {
      phone: phone || '',
      channel: CHANNEL, // xem report
      attributes: [...cart.attributes, ...arrEPCs]
    }

    await updateCart(cart.id, payload)
  }

  const onCheckout = async () => {
    try {
      onLoading(true)
      // UPDATE INFO CART
      await onUpdateInfoCart()
      // SUBMIT CART
      const cartResponse = await submitCart()
      if (cartResponse.is_error) return
      const order = cartResponse.data.order
      onSetOrder(order)
      // CHECKOUT PAYME
      const payload = {
        order_id: order.id,
        amount: order.total,
        order_number: order.orderName,
        payCode: methodId,
        username: appInfo?.paymeCode || ''
      }
      const paymeResponse = await payme(payload)

      if (paymeResponse?.is_error) return
      // CONFIRM PAYME
      const { partnerTransaction, amount } = paymeResponse.data.data
      const confirmPayload = {
        partnerTransaction,
        amount,
        state: 'SUCCEEDED',
        payCode: paymeResponse.data.data.payCode
      }
      await confirmPayme(confirmPayload)
      const orderResponse = await getOrder(order.id)
      if (orderResponse?.is_error) return
      await confirmOrder(order.id)
      await onFulfillment(orderResponse.data.data)

      onLoading(false)
      navigate('/payment-completed')
    } catch (error) {
      onLoading(false)
      navigate('/home')
    }
  }

  const onFulfillment = async (order: any) => {
    const fulfillPayload = {
      carrierShippingService: CARRIER_MODEL,
      order: {
        ...order,
        ...PAYMENT_TYPE_MODEL,
        orderProducts: null,

        locationId: appInfo?.location?.id || '',
        assignedLocationId: appInfo?.location?.id || '',
        totalWeight: order.totalWeight || 0
      },
      isSentEmail: true,
      listDetail: order?.orderProducts
    }
    await confirmFulfillmentOrder(order?.orderId, fulfillPayload)
  }
  const total = useMemo(() => {
    return cart?.lineItems?.reduce((sum: number, item: any) => sum + item.quantity, 0) ?? 0
  }, [cart])

  return (
    <div className='layout pb-40'>
      <Header title='paymentMethod.payment' />

      {(() => {
        switch (methodId) {
          case 'VIETQR':
            return <QRCode total={total} />
          case 'ATM_POS':
          case 'CREDIT_POS':
            return <POS total={total} />
          default:
            return null
        }
      })()}

      <Footer onNext={onCheckout} nextTitle='confirm' />
    </div>
  )
}
//viewChildren
const QRCode: React.FC<any> = ({ total }) => {
  const { cart } = useApp()
  const { t } = useTranslation()

  return (
    <>
      <p className='responsive-text mt-40'>
        {t('paymentMethod.amountPayableFor')} {total} {t('paymentMethod.items')}
      </p>

      <p className='mt-8 ml-2 responsive-text font-bold	content-danger'>{formatMoney(cart?.total)}</p>

      <p className='responsive-text mt-20 text-center mx-20'>{t('paymentMethod.scanQrCodeInPosMachine')}</p>

      <img style={{ marginTop: '40px', height: '30rem' }} src={qrCode} alt='img' />
    </>
  )
}

const POS: React.FC<any> = ({ total }) => {
  const { cart } = useApp()
  const { t } = useTranslation()

  return (
    <>
      <p className='responsive-text mt-40'>
        {t('paymentMethod.amountPayableFor')} {total} {t('paymentMethod.items')}
      </p>

      <p className='mt-8 ml-2 responsive-text font-bold	content-danger'>{formatMoney(cart?.total)}</p>

      <p className='responsive-text mt-20 text-center mx-20'>{t('paymentMethod.usePosMachineToCompletePayment')}</p>

      <img style={{ marginTop: '40px', height: `${30 * 0.65}rem`, width: '30rem' }} src={banking} alt='img' />
    </>
  )
}

export default PaymentMethodDetail

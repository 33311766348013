import { KEY_BOARD_TEXT_TYPE_1, KEY_BOARD_TEXT_TYPE_2 } from '@/src/constants'
import React, { useState } from 'react'
import './styles.css'

const KeyboardNumber: React.FC<any> = () => {
  const [keyboard, setIsKeyboard] = useState(KEY_BOARD_TEXT_TYPE_1)
  const [value, setValue] = useState('')
  const [isCaps, setIsCaps] = useState(false)

  const onClick = (val: string) => {
    switch (val) {
      case 'backspace':
        setValue(value.slice(0, -1))
        break
      case 'reset':
        setValue('')
        break
      case 'caps':
        setIsCaps(!isCaps)
        break
      case 'space':
        setValue(value + ' ')
        break
      case 'textformat':
        setIsKeyboard(KEY_BOARD_TEXT_TYPE_1)
        break
      case '!?#':
        setIsKeyboard(KEY_BOARD_TEXT_TYPE_2)
        break
      default:
        if (value?.length <= 30) {
          setValue(value + (isCaps ? val.toUpperCase() : val.toLowerCase()))
        }
    }
  }

  const isBold = (val: string) => {
    let temp = false
    if (val === 'caps' || val === 'backspace' || val === '!?#' || val === 'textformat') {
      temp = true
    }

    return temp
  }

  return (
    <>
      <div className='w-3/5 flex flex-col gap-4'>
        <p className='responsive-text-small'>LOGIN TO YOUR ACCOUNT</p>

        <div className='w-full py-4 px-2.5 border-2 border-blue-500 focus:ring-blue-500 focus:border-blue-500 rounded-lg flex items-center'>
          {value ? (
            <p className='responsive-text-small'>{value}</p>
          ) : (
            <p className='text-[#9ca3af] responsive-text-small'>Enter your email</p>
          )}
        </div>
      </div>

      <div className='text-center mt-10'>
        {keyboard?.map((val, index) => {
          if (val === 'br') {
            return <br key={index}></br>
          } else {
            return (
              <button
                type='button'
                onClick={() => onClick(val)}
                key={index}
                className={`${val === 'space' ? 'px-28' : 'px-8'} m-1.5 
                ${isBold(val) ? 'background-accent100' : 'bg-[#F6F7FB]'} 
                responsive-text-small
                 py-1.5 rounded-[40px] btn-custom`}
              >
                {val === 'textformat' && <Icon title='text_format' />}
                {val === 'backspace' && <Icon title='backspace' />}
                {val === 'caps' && <Icon title='keyboard_capslock' />}
                {val === 'reset' && <Icon title='clear' />}
                {val === 'space' && <Icon title='space_bar' />}

                {val !== 'backspace' &&
                  val !== 'caps' &&
                  val !== 'reset' &&
                  val !== 'textformat' &&
                  val !== 'space' && <>{isCaps ? val.toUpperCase() : val.toLowerCase()}</>}
              </button>
            )
          }
        })}
      </div>
    </>
  )
}

//viewChildren
const Icon: React.FC<any> = ({ title }) => {
  return <i className='material-icons'>{title}</i>
}

export default KeyboardNumber

import { updateCart } from '@/src/apis'
import { arrow_down } from '@/src/assets'
import { Footer, Header, Modal } from '@/src/components'
import {
  EMAIL_REGEX,
  INIT_INVOICE_COMPANY,
  INIT_INVOICE_PERSONAL,
  INVOICE_COMPANY,
  INVOICE_PERSONAL,
  INVOICE_TYPE,
  KEY_BOARD_TEXT_TYPE_1,
  KEY_BOARD_TEXT_TYPE_2
} from '@/src/constants'
import { useApp } from '@/src/context'
import { showToast } from '@/src/utils'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const combineCharacterAndTone = (base: string, tone: string): string => {
  const combinations: Record<string, Record<string, string>> = {
    a: { '̀': 'à', '́': 'á', '̉': 'ả', '̃': 'ã', '̣': 'ạ' },
    ă: { '̀': 'ằ', '́': 'ắ', '̉': 'ẳ', '̃': 'ẵ', '̣': 'ặ' },
    â: { '̀': 'ầ', '́': 'ấ', '̉': 'ẩ', '̃': 'ẫ', '̣': 'ậ' },
    e: { '̀': 'è', '́': 'é', '̉': 'ẻ', '̃': 'ẽ', '̣': 'ẹ' },
    ê: { '̀': 'ề', '́': 'ế', '̉': 'ể', '̃': 'ễ', '̣': 'ệ' },
    i: { '̀': 'ì', '́': 'í', '̉': 'ỉ', '̃': 'ĩ', '̣': 'ị' },
    o: { '̀': 'ò', '́': 'ó', '̉': 'ỏ', '̃': 'õ', '̣': 'ọ' },
    ô: { '̀': 'ồ', '́': 'ố', '̉': 'ổ', '̃': 'ỗ', '̣': 'ộ' },
    ơ: { '̀': 'ờ', '́': 'ớ', '̉': 'ở', '̃': 'ỡ', '̣': 'ợ' },
    u: { '̀': 'ù', '́': 'ú', '̉': 'ủ', '̃': 'ũ', '̣': 'ụ' },
    ư: { '̀': 'ừ', '́': 'ứ', '̉': 'ử', '̃': 'ữ', '̣': 'ự' },
    y: { '̀': 'ỳ', '́': 'ý', '̉': 'ỷ', '̃': 'ỹ', '̣': 'ỵ' },
    d: { '': 'đ' } // This is for 'd' to 'đ' without tone
  }

  return combinations[base]?.[tone] || base
}

const Invoice: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { onLoading, cart } = useApp()
  const [invoice, setInvoice] = useState<any>(INIT_INVOICE_PERSONAL)
  const [invoiceType, setInvoiceType] = useState<any>(INVOICE_TYPE[0])
  const [selectedKey, setSelectedKey] = useState<string>('')
  const [isModal, setIsModal] = useState<boolean>(false)

  const inputRefs = useRef<any>([])

  const setRef = (index: number, node: any | null) => {
    if (node) {
      inputRefs.current[index] = node
    }
  }

  const isValidInvoice = async (): Promise<boolean> => {
    let isFormValid = true

    const requiredFields = getRequiredFields()

    for (const field of requiredFields) {
      if (!invoice[field]) {
        shakeInput(field)
        showToast('warn', t('toast.pleaseEnterValue'))
        isFormValid = false
      }
    }

    return isFormValid
  }

  const getRequiredFields = (): string[] => {
    return invoiceType === 'Company'
      ? INVOICE_COMPANY.filter((field) => field.isRequired).map((field) => field.key)
      : INVOICE_PERSONAL.filter((field) => field.isRequired).map((field) => field.key)
  }

  const shakeInput = (field: string) => {
    const inputElement = inputRefs.current[field]
    if (inputElement) {
      inputElement.classList.add('animate-shake')
      setTimeout(() => {
        inputElement.classList.remove('animate-shake')
      }, 500)
    }
  }

  const isValidEmail = () => {
    if (!invoice['bill_email']) return true
    const isValid = EMAIL_REGEX.test(invoice['bill_email'])

    if (!isValid) showToast('warn', t('toast.pleaseEnterValidEmail'))
    return isValid
  }

  const onNext = async () => {
    const isInvoiceValid = await isValidInvoice()
    const isEmailValid = await isValidEmail()

    if (!isInvoiceValid || !isEmailValid) return

    onLoading(true)
    let payload = {
      attributes: [...cart.attributes, ...Object.entries(invoice).map(([key, value]) => ({ key, value }))]
    }

    let res = await updateCart(cart.id, payload)

    if (!res?.is_error) {
      showToast('success', t('toast.invoiceUpdated'))
      setIsModal(true)
    }
    onLoading(false)
  }

  const onConfirmCart = async () => {
    setIsModal(!isModal)
    navigate('/payment-method')
  }

  return (
    <div className='layout pb-40'>
      <Header title='paymentInvoice.einvoiceInformation' isBack={false} />

      <Body
        setRef={setRef}
        inputRefs={inputRefs}
        // INVOICE
        invoice={invoice}
        setInvoice={setInvoice}
        // INVOICE_KEY
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        // INVOICE_TYPE
        invoiceType={invoiceType}
        setInvoiceType={setInvoiceType}
      />

      <Modal
        title={'shop.confirmOrder'}
        isModal={isModal}
        txtLeft={'back'}
        onActionLeft={() => setIsModal(!isModal)}
        txtRight={'confirm'}
        onActionRight={onConfirmCart}
      />

      <Footer onNext={onNext} />
    </div>
  )
}

//viewChildren

const Body: React.FC<any> = ({
  setRef,
  invoice,
  setInvoice,
  selectedKey,
  setSelectedKey,
  invoiceType,
  setInvoiceType
}) => {
  const { t } = useTranslation()

  const [keyboard, setIsKeyboard] = useState(KEY_BOARD_TEXT_TYPE_1)
  const [isCaps, setIsCaps] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Handle change event for the select box
  const onChangeInvoice = (data: any) => {
    try {
      setInvoice(data.title === 'Company' ? INIT_INVOICE_COMPANY : INIT_INVOICE_PERSONAL)
      setInvoiceType(data)
      toggleDropdown()
    } catch (err) {
      console.log('err', err)
    }
  }

  const onKeyboardClick = (key: string) => {
    if (!selectedKey) return
    let temp = isCaps ? key.toUpperCase() : key.toLowerCase()

    switch (key) {
      case 'backspace':
        setInvoice((prevState: any) => ({
          ...prevState,
          [selectedKey]: invoice[selectedKey].slice(0, -1)
        }))
        break
      case 'reset':
        setInvoice((prevState: any) => ({
          ...prevState,
          [selectedKey]: ''
        }))
        break
      case 'caps':
        setIsCaps(!isCaps)
        break
      case 'space':
        setInvoice((prevState: any) => ({
          ...prevState,
          [selectedKey]: invoice[selectedKey] + ' '
        }))
        break
      case 'textformat':
        setIsKeyboard(KEY_BOARD_TEXT_TYPE_1)
        break
      case '!?#':
        setIsKeyboard(KEY_BOARD_TEXT_TYPE_2)
        break
      default:
        if (invoice[selectedKey]?.length <= 30) {
          // Handle tone marks
          const toneMarks = ['̀', '́', '̉', '̃', '̣']
          if (toneMarks.includes(key)) {
            setInvoice((prevState: any) => {
              const currentValue = prevState[selectedKey]
              const lastChar = currentValue?.slice(-1)
              const newChar = combineCharacterAndTone(lastChar, key)
              return {
                ...prevState,
                [selectedKey]: currentValue.slice(0, -1) + newChar
              }
            })
            return
          }

          setInvoice((prevState: any) => ({
            ...prevState,
            [selectedKey]: invoice[selectedKey] + temp
          }))
        }
    }
  }

  const isBold = (key: string) => {
    return key === 'reset' || key === 'backspace'
  }

  const INPUT = useMemo(() => {
    return invoiceType?.title === 'Company' ? INVOICE_COMPANY : INVOICE_PERSONAL
  }, [invoiceType])

  return (
    <>
      <div className='flex flex-col gap-4 w-full items-center mt-10'>
        <div className='w-3/5 flex flex-col gap-4 '>
          <p className='text-xl'>{t('paymentInvoice.invoiceType')}</p>
          <div ref={dropdownRef} className='relative inline-block '>
            <div
              onClick={toggleDropdown}
              className={`w-full h-14 px-2.5 border-2 rounded-lg flex items-center justify-between`}
            >
              <p className='text-xl'>{t(invoiceType.key)}</p>
              <img style={{ height: '0.5rem' }} src={arrow_down} alt={'arrow_down'} />

            </div>

            {isOpen && (
              <div className='absolute mt-2 w-full bg-white'>
                {INVOICE_TYPE?.map((val: any, index: number) => {
                  const isSelected = val?.title === invoiceType?.title

                  return (
                    <div
                      key={index}
                      onClick={() => onChangeInvoice(val)}
                      className={`flex items-center w-full px-4 py-2 hover:bg-gray-100 gap-4 ${isSelected ? 'bg-blue-200' : ''}`}
                    >
                      <p className='text-xl'> {t(val.key)}</p>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        {INPUT?.map((val: any, index: number) => {
          return (
            <div onClick={() => setSelectedKey(val.key)} key={index} className='w-3/5 flex flex-col gap-4'>
              <p className='text-xl'>
                {t(val.title).toUpperCase()} <span className='text-red-500'>{val.isRequired && '*'}</span>{' '}
              </p>

              <div
                ref={(node) => setRef(val.key as any, node)} // Assign ref to each div by key
                className={`w-full h-14 px-2.5 border-2 rounded-lg flex items-center ${selectedKey === val.key && 'border-blue-500 focus:ring-blue-500 focus:border-blue-500'}`}
              >
                {invoice[val.key] ? (
                  <p className='text-xl'>{invoice[val.key]} </p>
                ) : (
                  <p className='text-[#9ca3af] text-xl'> {t(val.placeholder)}</p>
                )}
              </div>
            </div>
          )
        })}
      </div>

      <div className='text-center mt-10'>
        {keyboard.map((key, index) => {
          if (key === 'br') {
            return <br key={index} />
          } else {
            return (
              <button
                type='button'
                onClick={() => onKeyboardClick(key)}
                key={index}
                className={`${key === 'space' ? 'w-[240px]' : 'w-[80px]'} m-1.5 
                  ${isBold(key) ? 'background-accent100' : 'bg-[#F6F7FB]'} 
                 h-[45px] rounded-[40px] btn-custom`}
              >
                {key === 'textformat' && <Icon title='text_format' />}
                {key === 'backspace' && <Icon title='backspace' />}
                {key === 'caps' && <Icon title='keyboard_capslock' />}
                {key === 'reset' && <Icon title='clear' />}
                {key === 'space' && <Icon title='space_bar' />}

                {key !== 'backspace' &&
                  key !== 'caps' &&
                  key !== 'reset' &&
                  key !== 'textformat' &&
                  key !== 'space' && <>{isCaps ? key.toUpperCase() : key.toLowerCase()}</>}
              </button>
            )
          }
        })}
      </div>
    </>
  )
}

const Icon: React.FC<any> = ({ title }) => {
  return <i className='material-icons'>{title}</i>
}

export default Invoice

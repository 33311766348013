import { UserContextType, ProviderProps } from '@/src/types'
import React, { createContext, useContext, useMemo, useState } from 'react'
const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider: React.FC<ProviderProps> = ({ children }) => {
  const [phone, setPhone] = useState<string>('')

  const value = useMemo(
    () => ({
      phone,
      setPhone
    }),
    [phone]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = (): UserContextType => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useApp must be used within an UserProvider')
  }
  return context
}

import { GET_API, POST_API, PUT_API } from '../configApi'
import { APP_INFO } from '@/src/constants'
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
export const getOrder = async (orderId: string): Promise<any> => {
  while (true) {
    const response = await GET_API({ endpoint: `admin/com_api/api/orders/${orderId}` })
    if (!response.is_error && response.data.data.isShowPaymentReceived && response.data.data.transactions.length > 0) {
      return response
    }

    await delay(2000)
  }
}

export const confirmOrder = async (orderId: string): Promise<any> => {
  return await PUT_API({ endpoint: `admin/com_api/api/orders/${orderId}/confirm` })
}

export const confirmFulfillmentOrder = async (orderId: string, payload: any): Promise<any> => {
  return await POST_API({ endpoint: `admin/com_api/api/orders/${orderId}/fulfillment_async`, data: payload })
}

export const payme = async (payload: any): Promise<any> => {
  return await POST_API({ endpoint: `admin/api/payme/create-payment`, data: payload })
}

export const confirmPayme = async (payload: any): Promise<any> => {
  return await POST_API({ endpoint: `admin/api/payme/ipn`, data: payload, isIncludeAuthorization: false })
}

export const getPrintTemplate = async (orderId: number, templateId: number): Promise<any> => {
  return await POST_API({ endpoint: `admin/retail_api/api/orders/${orderId}/template/${templateId}/print` })
}

export const comitHiGD = async (payload: any): Promise<any> => {
  const appInfo = JSON.parse(window.localStorage.getItem(APP_INFO) as string)
  return await POST_API({
    endpoint: `http://${appInfo?.higd}/api/Security/commit`,
    isIncludeAuthorization: false,
    isEndpointShop: false,
    data: payload
  })
}

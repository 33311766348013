import { KEY_BOARD_TEXT_TYPE_DEVICE_CODE, KEY_BOARD_TEXT_TYPE_PAYME, KEY_BOARD_TEXT_TYPE_IP } from '@/src/constants'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Keyboard: React.FC<{
  type: string
  isCaps?: boolean
  title: string
  placeHolder: string
  data: string
  setData: React.Dispatch<React.SetStateAction<string>>
  divRef?: React.RefObject<HTMLDivElement>
}> = ({ title, placeHolder, data, setData, divRef, isCaps = false, type }) => {
  const { t } = useTranslation()

  const [isCapsLock, setIsCapsLock] = useState(isCaps)

  const KEY_BOARD = useMemo(() => {
    switch (type) {
      case 'deviceCode':
        return KEY_BOARD_TEXT_TYPE_DEVICE_CODE
      case 'payme':
        return KEY_BOARD_TEXT_TYPE_PAYME
      default:
        return KEY_BOARD_TEXT_TYPE_IP
    }
  }, [type])

  const onKeyboardClick = (key: string) => {
    switch (key) {
      case 'backspace':
        setData(data.slice(0, -1))
        break
      case 'reset':
        setData('')
        break
      case 'caps':
        setIsCapsLock(!isCapsLock)
        break
      default:
        if (data.length <= 30) {
          setData(data + (isCapsLock ? key.toUpperCase() : key.toLowerCase()))
        }
    }
  }

  const isBold = (key: string) => {
    return key === 'reset' || key === 'backspace'
  }

  const txtPlaceHolder = useMemo(() => {
    return type === 'IP' ? 'XXX.XXX.XXX.XXX:XXX' : t(placeHolder)
  }, [type, placeHolder])

  return (
    <>
      <div className='w-3/5 flex flex-col gap-4'>
        <p className='text-xl'>{t(title)}</p>

        <div
          ref={divRef}
          className='w-full h-14 px-2.5 border-2 border-blue-500 focus:ring-blue-500 focus:border-blue-500 rounded-lg flex items-center'
        >
          {data ? <p className='text-xl'>{data}</p> : <p className='text-[#9ca3af] text-xl'>{txtPlaceHolder}</p>}
        </div>
      </div>

      <div className='text-center mt-10'>
        {KEY_BOARD.map((key, index) => {
          if (key === 'br') {
            return <br key={index} />
          } else {
            return (
              <button
                type='button'
                onClick={() => onKeyboardClick(key)}
                key={index}
                className={`${key === 'space' ? 'w-[240px]' : 'w-[80px]'} m-1.5 
                  ${isBold(key) ? 'background-accent100' : 'bg-[#F6F7FB]'} 
                 h-[45px] rounded-[40px] btn-custom`}
              >
                {key === 'backspace' && <Icon title='backspace' />}
                {key === 'reset' && <Icon title='clear' />}
                {key === 'caps' && <Icon title='keyboard_capslock' />}

                {key !== 'backspace' && key !== 'reset' && key !== 'caps' && (
                  <>{isCapsLock ? key.toUpperCase() : key.toLowerCase()}</>
                )}
              </button>
            )
          }
        })}
      </div>
    </>
  )
}

export default Keyboard

//viewChildren

const Icon: React.FC<any> = ({ title }) => {
  return <i className='material-icons'>{title}</i>
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import './styles.css'

interface IButton {
  title: any
  isTextWhite?: boolean
  isDisabled?: boolean
  className?: string
  onClick: () => void
}

const Button: React.FC<IButton> = ({ isDisabled, title, className, isTextWhite = true, onClick }) => {
  const { t } = useTranslation()

  return (
    <button
      onClick={() => !isDisabled && onClick()}
      className={`${className} ${isDisabled ? 'btn-disabled' : 'btn-custom'} background-accent700 w-3/5 py-8 rounded-lg `}
    >
      <p className={`${isTextWhite ? 'text-white' : 'content-accent700'} responsive-text`}>{t(title)}</p>
    </button>
  )
}

export default Button

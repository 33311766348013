import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface IHeader {
  title: string
  isBack?: boolean
}

const Header: React.FC<IHeader> = ({ title, isBack = true }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const onBack = () => {
    navigate('/home')
  }

  return (
    <>
      <div className='z-50 fixed top-0 flex w-full flex bg-white border-b-2 px-8 h-28'>
        <div className={'flex-1 flex items-center '}>
          <p className={'font-medium	responsive-text-small'}>{`${t(title)}`}</p>
        </div>
        <div className={'flex-1 flex items-center justify-center'}>
          <p className={'font-black responsive-text'}>MAISON</p>
        </div>
        <div className={'flex-1 flex items-center justify-end'}>
          {isBack && (
            <button onClick={onBack} className={'btn-custom background-accent100 px-4 py-4 rounded-md'}>
              <p className={'responsive-text-small content-accent700'}>{t('returnToMainPage')}</p>
            </button>
          )}
        </div>
      </div>

      <div className='flex w-full flex h-28' />
    </>
  )
}

export default Header

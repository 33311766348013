import { flagUk, flagVn } from '@/src/assets'

export const TOKEN = import.meta.env.VITE_APP_SID as string
export const APP_INFO = 'APP_INFO'
export const ID_CART = 'ID_CART'
export const USER_PHONE = 'USER_PHONE'
export const LANG = 'APP_LANG'
export const CHANNEL = 'self-checkout'
export const VERSION_APP = '1.0.0'

export const LANGUAGES = [
  {
    id: 0,
    lang: 'vi',
    title: 'Tiếng Việt',
    icon: flagVn
  },
  {
    id: 1,
    lang: 'en',
    title: 'English',
    icon: flagUk
  }
]

export const CART = {
  lineItems: [
    {
      pricingListId: null,
      sku: '100195960002_CS198',
      barcode: '196395350810',
      id: 3598157,
      seq: null,
      variantId: 1123338764,
      variantType: 0,
      productId: 1054735399,
      productTitle: 'COACH - Áo khoác denim nam cổ bẻ tay dài thời thượng 1',
      variantTitle: 'Đen / M / CS198',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/blk_cs198_1_fbe95079fb5d4e128f5ee2a5d4319406_1786418caf2348d8bee90eb5cd53eae1.jpg',
      priceOriginal: 13000000,
      price: 13000000,
      linePrice: 13000000,
      lineDiscount: 0,
      quantity: 4,
      weight: 800,
      lineWeight: 3200,
      lineTax: 3851851.8519,
      lineAmount: 52000000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    },
    {
      pricingListId: null,
      sku: '100185345003_CM545',
      barcode: '196395099597',
      id: 4694679,
      seq: null,
      variantId: 1123338757,
      variantType: 0,
      productId: 1054735397,
      productTitle: 'COACH -  Túi xách nữ phom hình thang thời trang',
      variantTitle: 'Be / ONE / CM545',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/b4iy_cm545_1_701d9ae1eb414bc7b133d91e4a222247_9e52de38a75d408baf064adef9772935.jpg',
      priceOriginal: 13000000,
      price: 13000000,
      linePrice: 13000000,
      lineDiscount: 0,
      quantity: 1,
      weight: 1650,
      lineWeight: 1650,
      lineTax: 962962.963,
      lineAmount: 13000000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    },
    {
      pricingListId: null,
      sku: '100196462001_5041024206',
      barcode: '50410242060011',
      id: 4694721,
      seq: null,
      variantId: 1123338718,
      variantType: 0,
      productId: 1054735383,
      productTitle: 'WEEKEND MAX MARA - Áo khoác măng tô cổ bẻ tay dài Alpino',
      variantTitle: 'Be / 2XS / 5041024206',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/01_5041024206_1_c0c75eb1d0334c479381ba9e6fd5c337_a879328d927a4d5fbf8ada15e17326d0.jpg',
      priceOriginal: 14990000,
      price: 14990000,
      linePrice: 14990000,
      lineDiscount: 0,
      quantity: 1,
      weight: 1950,
      lineWeight: 1950,
      lineTax: 1110370.3704,
      lineAmount: 14990000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    },
    {
      pricingListId: null,
      sku: '100196487001_5221094206',
      barcode: '52210942060011',
      id: 4696274,
      seq: null,
      variantId: 1123338736,
      variantType: 0,
      productId: 1054735391,
      productTitle: 'WEEKEND MAX MARA - Đầm midi cổ tròn tay dài phối hoạ tiết Ghiotto',
      variantTitle: 'Xanh dương / 34 / 5221094206',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/01_5221094206_1_df005b5686664177b52501594411de9c_0c81e540438249f2a77edee077aa8fc7.jpg',
      priceOriginal: 14490000,
      price: 14490000,
      linePrice: 14490000,
      lineDiscount: 0,
      quantity: 1,
      weight: 1500,
      lineWeight: 1500,
      lineTax: 1073333.3333,
      lineAmount: 14490000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    },
    {
      pricingListId: null,
      sku: '100196487004_5221094206',
      barcode: '52210942060014',
      id: 4696299,
      seq: null,
      variantId: 1123338739,
      variantType: 0,
      productId: 1054735391,
      productTitle: 'WEEKEND MAX MARA - Đầm midi cổ tròn tay dài phối hoạ tiết Ghiotto',
      variantTitle: 'Xanh dương / 40 / 5221094206',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/01_5221094206_1_df005b5686664177b52501594411de9c_0c81e540438249f2a77edee077aa8fc7.jpg',
      priceOriginal: 14490000,
      price: 14490000,
      linePrice: 14490000,
      lineDiscount: 0,
      quantity: 1,
      weight: 1500,
      lineWeight: 1500,
      lineTax: 1073333.3333,
      lineAmount: 14490000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    }
  ],
  pricingListId: null,
  pricingListName: null,
  customerId: null,
  saleUserId: null,
  createdUserId: null,
  webUrl:
    'https://checkouts.haravan.app/checkouts/api/200000396151/108e66ee5444471d0055f3802f62b844/auth?k=4fc9d1ba338bef610b675e5a079746d6',
  hasError: false,
  id: '108e66ee5444471d0055f3802f62b844',
  phone: null,
  fullName: null,
  shippingAddress: null,
  shippingCountryId: null,
  shippingProvinceId: null,
  shippingDistrictId: null,
  shippingWardId: null,
  shippingMethodId: null,
  shippingMethodName: null,
  paymentMethodName: null,
  paymentMethodId: null,
  payDepositOnly: false,
  depositRequired: false,
  eInvoiceRequest: true,
  eInvoiceInfo: null,
  utmParameters: null,
  depositAmount: 0,
  depositCODAmount: 0,
  note: null,
  requiresShipping: true,
  createdAt: '2024-06-26T02:52:12.495286Z',
  updatedAt: '2024-06-26T02:52:12.495287Z',
  completedAt: null,
  errors: [],
  warnings: [],
  userErrors: [],
  ready: true,
  shippingMethods: [],
  paymentMethods: [
    {
      id: 1003298983,
      name: 'Thanh toán khi giao hàng (COD)',
      desc: null,
      sysTypeId: 1,
      online: false
    },
    {
      id: 1003540576,
      name: 'Thanh toán công nợ',
      desc: null,
      sysTypeId: 4,
      online: false
    },
    {
      id: 1003628176,
      name: 'Thanh toán online qua ví MoMo',
      desc: null,
      sysTypeId: 21,
      online: true
    },
    {
      id: 1003630076,
      name: 'Thanh toán online qua ứng dụng ZaloPay bằng QRCode',
      desc: null,
      sysTypeId: 22,
      online: true
    },
    {
      id: 1003630078,
      name: 'Thanh toán online qua cổng ZaloPay bằng thẻ ATM nội địa',
      desc: null,
      sysTypeId: 23,
      online: true
    },
    {
      id: 1003742176,
      name: 'Thanh toán online qua cổng VNPay (ATM/Visa/MasterCard/JCB/QR Pay trên Mobile Banking)',
      desc: null,
      sysTypeId: 8,
      online: true
    },
    {
      id: 1003845601,
      name: 'Chuyển khoản qua ngân hàng',
      desc: null,
      sysTypeId: 2,
      online: false
    }
  ],
  discountAllocations: [],
  attributes: [],
  discount: 0,
  locationId: null,
  locationName: null,
  customerMembership: null,
  constraint: null,
  subTotalBeforeTax: 100898148.1481,
  subTotalTax: 8071851.8519,
  subTotal: 108970000,
  shippingBeforeTax: 100898148.1481,
  shippingTax: 0,
  shipping: 0,
  totalTaxIncluded: 8071851.8519,
  totalTaxNotIncluded: 0,
  total: 108970000,
  order: null,
  channel: 'haravan_draft_order',
  disallowLoyaltyProgram: false,
  sendNotify: false,
  sendReceipt: false,
  nextActions: null
}

export const INVOICE_COMPANY = [
  {
    id: 0,
    title: 'paymentInvoice.inputCompanyName',
    placeholder: 'paymentInvoice.enterCompanyName',
    key: 'paymentInvoice.bill_order_company',
    isRequired: true
  },
  {
    id: 1,
    title: 'paymentInvoice.inputAddress',
    placeholder: 'paymentInvoice.enterAddress',
    key: 'bill_order_address',
    isRequired: true
  },
  {
    id: 2,
    title: 'paymentInvoice.inputTaxNumber',
    placeholder: 'paymentInvoice.enterTaxNumber',
    key: 'bill_order_tax_code',
    isRequired: true
  },
  {
    id: 3,
    title: 'paymentInvoice.inputEmail',
    placeholder: 'paymentInvoice.enterEmail',
    key: 'bill_email',
    isRequired: false
  }
]

export const INVOICE_PERSONAL = [
  {
    id: 0,
    title: 'paymentInvoice.inputName',
    placeholder: 'paymentInvoice.enterName',
    key: 'bill_order_company',
    isRequired: true
  },
  {
    id: 1,
    title: 'paymentInvoice.inputAddress',
    placeholder: 'paymentInvoice.enterAddress',
    key: 'bill_order_address',
    isRequired: true
  },
  {
    id: 2,
    title: 'paymentInvoice.inputTaxNumber',
    placeholder: 'paymentInvoice.enterTaxNumber',
    key: 'bill_order_tax_code',
    isRequired: false
  },
  {
    id: 3,
    title: 'paymentInvoice.inputEmail',
    placeholder: 'paymentInvoice.enterEmail',
    key: 'bill_email',
    isRequired: false
  }
]

export const INVOICE_TYPE = [
  {
    id: 0,
    title: 'Personal',
    key: 'paymentInvoice.personal'
  },
  {
    id: 1,
    title: 'Company',
    key: 'paymentInvoice.company'
  }
]

export const INIT_INVOICE_COMPANY = {
  bill_order_company: '',
  bill_order_tax_code: '',
  bill_order_address: '',
  bill_email: ''
}

export const INIT_INVOICE_PERSONAL = {
  bill_order_company: '',
  bill_order_tax_code: '',
  bill_order_address: '',
  bill_email: ''
}

export const RFID = {
  rawData:
    'Get TagList\n 1 30340BFC184F94C000000019 912.75 -59\r\n 1 30340BFC184F94C00000001E 912.75 -59\r\n 1 30340BFC184F94C00000001C 912.75 -47\r\n 1 30340BFC184F94C00000001D 912.75 -43\r\n 1 30340BFC184F94C000000007 912.75 -58\r\n 1 30340BFC184F94C00000000B 920.75 -55\r\n 1 30340BFC184F94C000000008 912.75 -51\r\n 1 30340BFC184F94C000000002 920.75 -50\r\n 1 30340BFC184F94C00000000A 909.75 -54\r\n 1 30340BFC184F94C000000004 909.75 -57\r\nTotal 10\r\nmpos> ',
  errorMessage: '',
  createdAt: '2024-08-05T08:45:34.991Z',
  usbVendorId: 1027,
  usbProductId: 24597,
  listEPC: [
    '30340BFC184F94C000000019',
    '30340BFC184F94C00000001E',
    '30340BFC184F94C00000001C',
    '30340BFC184F94C00000001D',
    '30340BFC184F94C000000007',
    '30340BFC184F94C00000000B',
    '30340BFC184F94C000000008',
    '30340BFC184F94C000000002',
    '30340BFC184F94C00000000A',
    '30340BFC184F94C000000004'
  ],
  listBarcode: [
    {
      barcode: '196395246779',
      qty: 2,
      epcs: ['30340BFC184F94C000000019', '30340BFC184F94C00000001E']
    },
    {
      barcode: '196395246878',
      qty: 3,
      epcs: ['30340BFC184F94C00000001C', '30340BFC184F94C00000001D', '30340BFC184F94C000000007']
    },
    {
      barcode: '196395308620',
      qty: 4,
      epcs: [
        '30340BFC184F94C00000000B',
        '30340BFC184F94C000000008',
        '30340BFC184F94C000000002',
        '30340BFC184F94C00000000A'
      ]
    },
    {
      barcode: '52610143060012',
      qty: 8,
      epcs: [
        '30340BFC184F94C00000000B',
        '30340BFC184F94C000000008',
        '30340BFC184F94C000000002',
        '30340BFC184F94C00000000A',
        '30340BFC184F94C00000000C',
        '30340BFC184F94C00000000D',
        '30340BFC184F94C00000000F',
        '30340BFC184F94C00000000I'
      ]
    },
    {
      barcode: '193314167504',
      qty: 8,
      epcs: [
        '30342BFC184F94C000000004',
        '30340BFC184F94C000000007',
        '30340BFC184F94C000000002',
        '30340BFC184F94C00000000A',
        '30340BFC184F94C00020000C',
        '30340B2C184F94C00004000D',
        '30340BFC184F95C00000000F',
        '303404FC184F94C06000000I'
      ]
    },
    {
      barcode: '197627200606',
      qty: 4,
      epcs: [
        '30340BFC184F94C00000000B',
        '30340BFC184F94C005000008',
        '30340BFC184F94C000000002',
        '30340BFC184F94C00003000A'
      ]
    },
    {
      barcode: '197627797182',
      qty: 1,
      epcs: ['30340BFC184F94C60000000B']
    }
  ]
}

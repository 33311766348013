export const PAYMENT_METHOD = [
  {
    id: 0,
    title: 'VietQR',
    link: '/payment-method/VIETQR',
  },
  {
    id: 1,
    title: 'ATM Card',
    link: '/payment-method/ATM_POS',
  },
  {
    id: 2,
    title: 'Credit',
    link: '/payment-method/CREDIT_POS',
  }
]

export const CARRIER_MODEL = {
  id: 0,
  name: '',
  packageId: 1390170,
  packageName: 'Khác',
  isActived: true,
  isShowPackageDimension: true,
  isShowPaymentMethod: false,
  isShowPaidBy: false,
  packageHeight: 0,
  packageLength: 0,
  packageWidth: 0,
  isShowCouponCode: false,
  carrierDiscountCode: '',
  isViewBefore: false,
  isShowViewBefore: true,
  isShownAllowTest: true,
  allowTest: false,
  isViewSenderInfo: false,
  transportType: 0,
  hasInsurance: false,
  paidByReceiver: false,
  isPickupTime: false,
  isPickupDateTime: false,
  codFee: 0,
  fee: 0,
  isInsurance: false,
  insurancePrice: 0
}

export const PAYMENT_TYPE_MODEL = {
  gHNPaymentType: 1,
  gHN2018PaymentType: 1,
  viettelPaymentType: 1,
  shipchungPaymentType: 1,
  proShipPaymentType: 1,
  vNPostPaymentType: 1,
  vNPostPlusPaymentType: 1,
  flexShipPaymentType: 1,
  gHTKPaymentType: 1,
  ninjavanPaymentType: 1,
  isCallNewCreateShipping: true
}

import { useNavigate } from 'react-router-dom'
import { Header, Footer, Button } from '@/src/components'
const Login: React.FC = () => {
  const navigate = useNavigate()

  const onNavigate = (path: any) => {
    navigate(path)
  }
  return (
    <div style={{ height: 'calc(100vh - 7rem)' }} className='layout'>
      <Header title='Member Login' />
      <Button
        onClick={() => {
          onNavigate('/login-phone')
        }}
        className='mt-8'
        title='MOBILE LOGIN'
      />
      <Button
        onClick={() => {
          onNavigate('/login-email')
        }}
        className='mt-8'
        title='EMAIL LOGIN'
      />

      <Footer />
    </div>
  )
}

export default Login

import { comitHiGD, getPrintTemplate, submitCart } from '@/src/apis'
import { complete, loading_checkout } from '@/src/assets/'
import { Button, Header } from '@/src/components'
import { APP_INFO, ID_CART } from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { printReceipt, useLocalStorage } from '@/src/utils'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Completed: React.FC = () => {
  const { t } = useTranslation()

  const isInitialMount = useRef(true)

  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const { onSetOrder, order, RFID, setRFID, onClearCart } = useApp()

  const [isComplete, setIsComplete] = useState(false)
  const [printTemplate, setPrintTemplate] = useState<any>(null)

  const htmlRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // PREVENT CALL API 2 TIMES IN STRICTMODE
    if (isInitialMount.current) {
      onLoadData()
      isInitialMount.current = false
    }
  }, [])

  useEffect(() => {
    // This effect runs when the component renders and `htmlRef` is populated
    if (htmlRef.current) onPrint()
  }, [printTemplate]) // Run this effect whenever `printTemplate` changes

  const onPrintTemplate = async () => {
    const response = await getPrintTemplate(order?.id, 2)

    if (!response?.is_error) {
      setPrintTemplate(response.data.data.data)
    }
  }

  const onCompleteCheckout = async () => {
    await onPrintTemplate()

    setIsComplete(true)
    setIdCart(null)
    onSetOrder(null)
    onClearCart()
    setRFID([])
  }

  const onLoadData = async () => {
    const response = await submitCart()

    if (!response.is_error) {
      // await onSubmitHiGD()
      await onCompleteCheckout()
    }
  }

  const onSubmitHiGD = async () => {
    const payload = {
      storeName: appInfo?.location?.name || '',
      deviceCode: appInfo?.deviceCode || '',
      securityEpc: RFID.map((item: any) => ({ EPC: item, Flag: 0 })), //Flag :0:epc, 1:barcode
      orderType: 1 //Status（1：receive；2：return）
    }

    await comitHiGD(payload)
  }

  const onPrint = async () => {
    if (htmlRef.current === null) return

    const dataPrint = htmlRef.current
    dataPrint.classList.remove('absolute')
    dataPrint.classList.remove('-z-10')

    await printReceipt(dataPrint)
  }

  return (
    <div className='layout pb-40 bg-white static'>
      <Header title='paymentCompleted.payment' />
      {!isComplete ? (
        <img style={{ height: '48rem', marginTop: '5rem' }} src={loading_checkout} alt='img' />
      ) : (
        <>
          <p className='responsive-text mt-40'>{t('paymentCompleted.paymentComplete').toUpperCase()}</p>

          <div className='flex flex-col justify-center items-center'>
            <p className='responsive-text mt-20 text-center '>
              {t('paymentCompleted.dontForgetToTakeReceiptAndBelonging')}
            </p>

            <img style={{ height: `${30 * 0.65}rem`, width: '30rem', marginTop: '2rem' }} src={complete} alt='img' />

            <p className='responsive-text mt-20 text-center'>{t('paymentCompleted.thankYouForShoppingWithUs')}</p>
            <p className='responsive-text text-center mt-2'>{t('paymentCompleted.lookFarwardToVisitAgain')}</p>
          </div>

          {printTemplate && (
            <div className='absolute -z-10'>
              <div className='w-[400px]' ref={htmlRef} dangerouslySetInnerHTML={{ __html: printTemplate }} />
            </div>
          )}
        </>
      )}

      {isComplete && <Footer />}
    </div>
  )
}

//viewChildren
const Footer: React.FC<any> = () => {
  const navigate = useNavigate()

  const onBack = () => {
    navigate('/home')
  }

  return (
    <div className='fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-20 justify-center'>
      <Button
        isTextWhite={false}
        title={'paymentCompleted.close'}
        onClick={onBack}
        className='bg-white border-2 border-blue-800	 text-blue-600'
      />
    </div>
  )
}

export default Completed

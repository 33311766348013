import { useApp } from '@/src/context'
import { formatMoney } from '@/src/utils'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.css'
const TABLE_HEADER = [
  {
    id: 0,
    title: 'NO.',
    key: 'cart.no'
  },
  {
    id: 0,
    title: 'SKU NO',
    key: 'cart.skuNo'
  },
  {
    id: 0,
    title: 'Item Name',
    key: 'cart.itemName'
  },
  {
    id: 0,
    title: 'Size',
    key: 'cart.size'
  },
  {
    id: 0,
    title: 'Price',
    key: 'cart.price'
  }
]

const Cart: React.FC<any> = ({ isInvoice, setIsInvoice }) => {
  return (
    <div className='pb-16 w-full' >
      <TableHeader />
      <TableBody />

      <Amount isInvoice={isInvoice} setIsInvoice={setIsInvoice} />
    </div>
  )
}

//viewChildren
const TableHeader: React.FC<any> = () => {
  const { t } = useTranslation()

  const width = (title: string) => {
    let temp = 'w-1/6'
    switch (title) {
      case 'NO.':
        temp = 'w-1/6'
        break
      case 'Size':
      case 'Price':
        temp = 'w-2/6'
        break
      case 'SKU NO':
      case 'Item Name':
        temp = 'w-3/6'
        break
      default:
        break
    }

    return temp
  }
  //
  return (
    <>
      <div className='fixed top-28 w-full flex border-b border-black py-4 bg-[#F6F7FB] '>
        {TABLE_HEADER?.map((val, index) => {
          return (
            <p
              key={index}
              className={`responsive-text text-black  flex justify-center items-center ${width(val.title)}`}
            >
              {t(val.key)}
            </p>
          )
        })}
      </div>
      <div className='h-28' />
    </>
  )
}

const TableBody: React.FC<any> = () => {
  const { cart } = useApp()

  return (
    <>
      {cart?.lineItems?.length > 0 && (
        <div className={'w-full'}>
          {cart?.lineItems?.map((val: any, index: number) => {
            return (
              <div key={index} className={`bg-[${index % 2 != 0 ? '#F6F7FB' : '#fff'}]`}>
                <Item item={val} index={index} isLast={index === cart?.lineItems?.length - 1} />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

const Item: React.FC<any> = ({ item, index, isLast }) => {
  return (
    <div className={`responsive-text-small flex w-full py-4  border-black ${isLast ? '' : 'border-b'}`}>
      <p className={'responsive-text-small flex w-1/6 flex justify-center items-center'}>{index + 1}</p>
      <p className={'responsive-text-small flex w-3/6 flex justify-center items-center'}>{item.sku}</p>
      <p className={'responsive-text-small flex w-3/6 flex justify-center items-center text-center px-4'}>
        {item.productTitle}
      </p>
      <p className={'responsive-text-small flex w-2/6 flex justify-center items-center  text-center '}>
        {item.variantTitle}
      </p>
      <p className={'responsive-text-small flex w-2/6 flex justify-center items-center '}>{formatMoney(item.price)}</p>
    </div>
  )
}

const Amount: React.FC<any> = ({ isInvoice, setIsInvoice }) => {
  const { t } = useTranslation()

  const { cart } = useApp()

  const handleChange = () => {
    setIsInvoice(!isInvoice)
  }

  const total = useMemo(() => {
    return cart?.lineItems?.reduce((sum: number, item: any) => sum + item.quantity, 0) ?? 0
  }, [cart])

  return (
    <div className='bg-white flex flex-col fixed  w-full justify-end items-end p-8 mb-4 gap-2 amount'>
      <div className='flex'>
        <p className='responsive-text-small'>
          {total} {t('cart.items')},
        </p>
        <p className='responsive-text-small'>{t('cart.total')}:</p>
        <p className='responsive-text-small ml-2 font-bold content-danger'>{formatMoney(cart?.total)}</p>
      </div>
      <div className='flex items-center space-x-2'>
        <input
          type='checkbox'
          id='checkbox'
          checked={isInvoice}
          onChange={handleChange}
          className='h-6 w-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500'
        />
        <label htmlFor='checkbox' className='text-gray-700'>
          <p className='responsive-text-small'>{t('paymentInvoice.einvoiceInformation')}</p>
        </label>
      </div>
    </div>
  )
}

export default Cart

import { ID_CART } from '@/src/constants'
import { GET_API, POST_API, PUT_API } from '../configApi'
const URL_CART = 'admin/com_api/api/checkouts_v2/session'

export const getCart = async (): Promise<any> => {
  const idCart = window.localStorage.getItem(ID_CART)?.replace(/"/g, '') as string
  return await GET_API({ endpoint: `${URL_CART}/${idCart}` })
}

export const addCart = async (cartData: any): Promise<any> => {
  return await POST_API({ endpoint: URL_CART, data: cartData });
};

export const updateCart = async (idCart: string, payload: any): Promise<any> => {
  return await PUT_API({ endpoint: `${URL_CART}/${idCart}`, data: payload })
}

export const submitCart = async (): Promise<any> => {
  const idCart = window.localStorage.getItem(ID_CART)?.replace(/"/g, '') as string
  return await POST_API({ endpoint: `${URL_CART}/${idCart}` })
}
import { Footer, Header } from '@/src/components'
const Register: React.FC = () => {
  return (
    <div style={{ height: 'calc(100vh - 7rem)' }} className='layout'>
      <Header title='Register' />

      <Footer />
    </div>
  )
}

export default Register

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './styles.css'

interface IFooter {
  onNext?: () => void
  nextTitle?: string
  isDisabledNext?: boolean
  isNext?: boolean
}

const Footer: React.FC<IFooter> = ({ onNext, nextTitle, isDisabledNext, isNext = true }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const onBack = () => {
    navigate(-1)
  }

  return (
    <div className='z-50 fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-20 justify-between'>
      <button onClick={onBack} className='button-prev footer'>
        <p className='content-accent700 responsive-text'>{t('back')}</p>
      </button>

      {isNext && onNext && (
        <button onClick={() => (isDisabledNext ? null : onNext())} className='button-next footer'>
          <p className='text-white responsive-text'>{t(nextTitle || 'confirm')}</p>
        </button>
      )}
    </div>
  )
}

export default Footer

import { createDeviceCode, getDeviceCode } from '@/src/apis/'
import { Footer, Header, Modal } from '@/src/components'
import { APP_INFO } from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { showToast, useLocalStorage, isValidateCode } from '@/src/utils'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Keyboard } from '../components'

const DeviceCode: React.FC = () => {
  const { t } = useTranslation()

  const divRef = useRef<HTMLDivElement>(null)

  const { onLoading } = useApp()

  const navigate = useNavigate()
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const [deviceCode, setDeviceCode] = useState<string>(appInfo?.deviceCode ?? '')
  const [isModal, setIsModal] = useState<boolean>(false)

  const onNext = async () => {
    if (!isValidateCode(deviceCode)) {
      showToast('warn', t('toast.invalidDeviceCode'))
      divRef?.current?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      divRef?.current?.classList.remove('animate-shake')
      return
    }
    const res = await getDeviceCode(deviceCode)

    if (!res.data) {
      onCreate()
    } else {
      setIsModal(true)
    }
  }

  const onCreate = async () => {
    onLoading(true)

    try {
      const body = {
        device_code: deviceCode
      }
      const res = await createDeviceCode(body)

      if (!res?.is_error) onSave(deviceCode)
    } catch (err) {
      console.log(err)
    }
    onLoading(false)
  }

  const onSave = (deviceCode: string) => {
    setAppInfo({
      ...appInfo,
      deviceCode: deviceCode
    } as IAppInfoLocalStorage)
    navigate(-1)
    showToast('success', t('toast.deviceCodeUpdated'))
    setIsModal(false)
  }

  return (
    <div className='layout pt-40'>
      <Header title='settings.deviceCode' />
      <Keyboard
        type={'deviceCode'}
        data={deviceCode}
        setData={setDeviceCode}
        divRef={divRef}
        title={'settings.inputDeviceCode'}
        placeHolder={'settings.enterDeviceCode'}
        isCaps={true}
      />
      <Footer onNext={onNext} nextTitle={'Confirm'} />

      <Modal
        title={'Device code was exist. Would you like to create it?'}
        isModal={isModal}
        onActionRight={() => {
          onSave(deviceCode)
        }}
      />
    </div>
  )
}

export default DeviceCode

import { updateDeviceCode } from '@/src/apis'
import { Footer, Header } from '@/src/components'
import { APP_INFO } from '@/src/constants'
import { IAppInfoLocalStorage } from '@/src/types'
import { isValidateIPAddress, showToast, useLocalStorage } from '@/src/utils'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Keyboard } from '../components'
const HiGD: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)


  const divRef = useRef<HTMLDivElement>(null)
  const [port, setPort] = useState<string>(appInfo?.higd ?? '')

 

  const onNext = async () => {
    if (!isValidateIPAddress(port)) {
      showToast('warn', t('toast.invalidIP'))
      divRef?.current?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      divRef?.current?.classList.remove('animate-shake')
      return
    }

    await updateDeviceCode({
      device_code: appInfo?.deviceCode,
      higd: port
    })

    setAppInfo({
      ...appInfo,
      higd: port
    } as IAppInfoLocalStorage)
    navigate(-1)
    showToast('success', t('toast.higdUpdated'))

  }

  return (
    <div className='layout pt-40'>
      <Keyboard
        type={'IP'}
        data={port}
        setData={setPort}
        divRef={divRef}
        title={'settings.higd'}
        placeHolder={'settings.higd'}
        isCaps={true}
      />
      <Header title='settings.higd' />
      <Footer onNext={onNext} nextTitle={'Confirm'} />
    </div>
  )
}

export default HiGD

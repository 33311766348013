import { loading as loadingGif } from '@/src/assets/'
import { useApp } from '@/src/context'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Loading: React.FC = () => {
  const { isLoading, onLoading } = useApp()

  const location = useLocation()

  useEffect(() => {
    // KHI ROUTE CHANGE, LOADING VẪN CÒN XOAY THÌ TẮT ĐI
    if (isLoading) onLoading(false)
  }, [location])

  if (!isLoading) return null

  return (
    <div className='flex w-full h-full fixed top-0 left-0 bg-white opacity-75 z-40 justify-center items-center'>
      <img className='w-20 h-20' src={loadingGif} alt='Loading' />
    </div>
  )
}

export default Loading

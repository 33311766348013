import { getCurrentDate } from '@/src//utils'
import { arrow_down } from '@/src/assets'
import { Button } from '@/src/components'
import { APP_INFO, LANGUAGES } from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { showToast, useLocalStorage } from '@/src/utils'
import i18next from 'i18next'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './styles.css'
const Home: React.FC = () => {
  const { t } = useTranslation()

  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  const { onClearCart, setRFID } = useApp()
  const navigate = useNavigate()

  const onNavigate = (path: string) => {
    navigate(path)
  }

  const onStart = () => {
    onClearCart()
    setRFID([])
    onNavigate('/login-phone')
  }

  const isDisabled = useMemo(() => {
    const { deviceCode, location, paymeCode, higd } = appInfo || {}
    return deviceCode && location && paymeCode && higd ? false : true
  }, [appInfo])

  return (
    <div className='layout pb-40 bg-white'>
      <div className='relative layout w-full flex justify-center bg-white home-banner h-[600px]'>
        <div className='absolute bottom-20'>
          <p className='font-bold responsive-text text-white'>MAISON</p>
        </div>
      </div>

      <div className='layout w-full'>
        <Language />

        <p className={'responsive-text mt-12'}>{t(`home.welcomeTo`)} MAISON</p>
        <p className={'font-bold mt-6 responsive-text'}>{t(`home.selfCheckout`)}</p>

        <Button onClick={onStart} className='mt-20' title='home.start' isDisabled={isDisabled} />
      </div>

      <Footer />
    </div>
  )
}

//viewChildren
const Language: React.FC<any> = () => {
  const { t } = useTranslation()

  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const onChangeLanguage = async (newLang: string) => {
    i18next.changeLanguage(newLang)

    setAppInfo({
      ...appInfo,
      language: newLang
    } as IAppInfoLocalStorage)

    showToast('success', t('toast.languageUpdated'))
    setIsOpen(false)
  }

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const selectedOption = useMemo(() => {
    if (!appInfo?.language) return LANGUAGES.find((item) => item.lang === 'vi')
    return LANGUAGES.find((item) => item.lang === appInfo?.language)
  }, [appInfo])

  return (
    <div className='flex w-full justify-end mt-10 px-10'>
      <button className='flex items-center justify-center gap-2'>
        <p className={'responsive-text-small'}>{t(`settings.language`)}:</p>
        <div ref={dropdownRef} className='relative inline-block w-64'>
          <div
            onClick={toggleDropdown}
            className='flex items-center justify-between w-full text-left border-b border-gray-300 bg-white px-4 py-2'
          >
            <div className='flex gap-4'>
              <img style={{ height: '2rem' }} src={selectedOption?.icon} alt={selectedOption?.title} />
              <p className='responsive-text-small'>{selectedOption?.title}</p>
            </div>

            <img style={{ height: '0.5rem' }} src={arrow_down} alt={'arrow_down'} />
          </div>
          {isOpen && (
            <div className='absolute mt-2 w-full bg-white'>
              {LANGUAGES?.map((val, index) => {
                const isSelected = val?.lang === selectedOption?.lang
                return (
                  <div
                    key={index}
                    onClick={() => onChangeLanguage(val?.lang)}
                    className={`flex items-center w-full px-4 py-2 hover:bg-gray-100 gap-4 ${isSelected ? 'bg-blue-200' : ''}`}
                  >
                    <img style={{ height: '2rem' }} src={val?.icon} alt={val?.title} />
                    <p className='responsive-text-small'>{val?.title}</p>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </button>
    </div>
  )
}

const Footer: React.FC<any> = ({}) => {
  const navigate = useNavigate()

  const onNavigate = () => {
    navigate('/settings')
  }

  return (
    <div className='fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-14 justify-between items-center'>
      <p onClick={onNavigate} className={'responsive-text-small'}>
        {getCurrentDate()}
      </p>
    </div>
  )
}

export default Home

import { APP_INFO, MONTH_EN, MONTH_VI } from '@/src/constants'
import { IAppInfoLocalStorage, IDecodedToken } from '@/src/types'
import { jwtDecode } from 'jwt-decode'
import { Slide, toast, ToastOptions } from 'react-toastify'
export const formatMoney = (input: number): string => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  })
  return formatter.format(input).replace(/\./g, ',')
}

export const formatDate = (dateString: string): string => {
  // Create a Date object from the input string
  const date = new Date(dateString)

  // Create options for date and time formatting
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Asia/Ho_Chi_Minh',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }

  // Use Intl.DateTimeFormat to format the date and time
  const formatter = new Intl.DateTimeFormat('en-GB', options) // en-GB for dd/MM/yyyy format
  const parts = formatter.formatToParts(date)

  // Extract and construct formatted date string
  const day = parts.find((part) => part.type === 'day')?.value ?? ''
  const month = parts.find((part) => part.type === 'month')?.value ?? ''
  const year = parts.find((part) => part.type === 'year')?.value ?? ''
  const hour = parts.find((part) => part.type === 'hour')?.value ?? ''
  const minute = parts.find((part) => part.type === 'minute')?.value ?? ''
  const second = parts.find((part) => part.type === 'second')?.value ?? ''

  // Construct the final formatted date string
  return `${day}/${month}/${year} ${hour}:${minute}:${second}`
}

export const decodeToken = (token: string): IDecodedToken => {
  return jwtDecode(token) as IDecodedToken
}

export const isTokenExpired = (token: string | null): boolean => {
  if (!token) {
    return true
  }

  try {
    const decoded: any = jwtDecode<any>(token)
    const currentTime = Math.floor(Date.now() / 1000)
    // THỜI GIAN HẾT HẠN LÙI VỀ 30S
    return currentTime > decoded.exp - 30
  } catch (error) {
    console.error('Failed to decode token', error)
    return true
  }
}

export const showToast = (type: 'success' | 'warn', message: string): void => {
  const toastId = 'unique-toast-id' // Unique ID for the toast

  const options: ToastOptions = {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    transition: Slide,
    toastId
  }

  if (!toast.isActive(toastId)) {
    // preventing a new toast from showing if there’s already an active toast
    if (type === 'success') {
      toast.success(message, options)
    } else if (type === 'warn') {
      toast.warn(message, options)
    }
  }
}

//
export const getCurrentDate = (): string => {
  const appInfo: IAppInfoLocalStorage | null = getAppInfoFromLocalStorage()

  const date: Date = new Date()
  const currentMonth: string = getCurrentMonth(appInfo, date)
  const currentYear: number = date.getFullYear()
  const currentHours: string = formatHours(date)
  const currentMinutes: string = formatMinutes(date)

  if (!appInfo?.language) {
    return `${date.getDate()} ${currentMonth} ${currentYear} ${currentHours}:${currentMinutes}`
  } else {
    return appInfo?.language === 'vi'
      ? `${date.getDate()} ${currentMonth} ${currentYear} ${currentHours}:${currentMinutes}`
      : `${currentMonth} ${date.getDate()} ${currentYear} ${currentHours}:${currentMinutes}`
  }
}

const getAppInfoFromLocalStorage = (): IAppInfoLocalStorage | null => {
  const storedData = window.localStorage.getItem(APP_INFO)
  return storedData ? (JSON.parse(storedData) as IAppInfoLocalStorage) : null
}

const getCurrentMonth = (appInfo: IAppInfoLocalStorage | null, date: Date): string => {
  const monthIndex: number = date.getMonth()
  const monthArray: string[] = !appInfo?.language ? MONTH_VI : appInfo?.language === 'vi' ? MONTH_VI : MONTH_EN
  return monthArray[monthIndex]
}

const formatHours = (date: Date): string => {
  return date.getHours().toString().padStart(2, '0')
}

const formatMinutes = (date: Date): string => {
  return date.getMinutes().toString().padStart(2, '0')
}
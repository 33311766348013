import { updateDeviceCode } from '@/src/apis/'
import { Header } from '@/src/components'
import { APP_INFO, SETTINGS, TOKEN, VERSION_APP } from '@/src/constants'
import { IAppInfoLocalStorage } from '@/src/types'
import { registerPrinter, useLocalStorage } from '@/src/utils'
import scanner from '@/src/utils/rfid'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Settings: React.FC = () => {
  return (
    <div className='layout pb-40'>
      <Header title='settings.setting' />
      <Body />
      <div className='h-28' />
    </div>
  )
}

//viewChildren
const Body: React.FC<any> = () => {
  const [token, setToken] = useLocalStorage<IAppInfoLocalStorage | string>(TOKEN, '')
  const [appInfo, setAppInfo, clearValue, clearAllValue] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const onClick = async (link: string) => {
    navigate(link)
  }

  const mapToValue = (key: string): string => {
    const { deviceCode, location, paymeCode, higd } = appInfo ?? {}

    if (key === 'DEVICE CODE') {
      return deviceCode ?? ''
    }

    if (key === 'LOCATIONS') {
      if (!location) return ''
      return `${location.provinceName} - ${location.countryName} - ${location.districtName} - ${location.name} - ${location.address} - ${location.phone} - ${location.email}`
    }

    if (key === 'PAYME CODE') {
      return paymeCode ?? ''
    }

    if (key === 'HiGD') {
      return higd ?? ''
    }

    return ''
  }

  const onLogout = () => {
    clearAllValue()
    setToken('')
    navigate('/admin')
  }

  const CONFIGS = [
    {
      id: 0,
      title: `${t('settings.rfid').toLocaleUpperCase()}`,
      onClick: async () => {
        let device = await scanner.pairDevice()
        if (device) {
          setAppInfo({
            ...appInfo,
            rfid: { vendorId: device.usbVendorId, productId: device.usbProductId }
          } as IAppInfoLocalStorage)

          await updateDeviceCode({
            device_code: appInfo?.deviceCode,
            rfid: { vendorId: device.usbVendorId, productId: device.usbProductId }
          })
        }
      }
    },
    {
      id: 1,
      title: `${t('settings.printer').toLocaleUpperCase()}`,
      onClick: async () => {
        let device = await registerPrinter()
        if (device) {
          await updateDeviceCode({
            device_code: appInfo?.deviceCode,
            printer: { vendorId: device.vendorId, productId: device.productId }
          })
        }
      }
    },
    {
      id: 2,
      title: `${t('settings.logOut').toLocaleUpperCase()}`,
      onClick: () => onLogout()
    }
    // {
    //   id: 3,
    //   title: `${t('settings.rfidTool').toLocaleUpperCase()}`,
    //   onClick: () => navigate('/settings-rfid-tool')
    // }
  ]

  const isDisbledSetting = useMemo(() => {
    return appInfo?.deviceCode ? false : true
  }, [appInfo])

  return (
    <div className={'flex flex-col h-screen w-screen items-center pt-20 gap-5 mb-20'}>
      {SETTINGS?.map((val, index) => {
        const isDisabledButton = val?.key === 'deviceCode' ? false : isDisbledSetting
        return (
          <div className={'w-3/5'} key={index}>
            <p className='responsive-text-small'>{t(`settings.${val.key}`)}</p>

            <div className='flex justify-center imtems-center mt-4'>
              <div className='w-full min-h-28 px-2.5 border-2  rounded-lg flex items-center'>
                <p className={'text-[#9ca3af] responsive-text-small'}>{mapToValue(val.title)}</p>
              </div>
              <button
                disabled={isDisabledButton}
                onClick={() => onClick(val.link)}
                className={`${isDisabledButton ? 'btn-disabled' : 'btn-custom'}  background-accent700 h-28 w-1/3 rounded-lg ml-10`}
              >
                <p className={'responsive-text-small text-white'}>{t(`settings.edit`)}</p>
              </button>
            </div>
          </div>
        )
      })}

      <div className='flex w-3/5 mt-10 flex-wrap justify-between gap-y-8'>
        {CONFIGS?.map((val, index) => {
          return (
            <button
              key={index}
              onClick={() => val.onClick()}
              className={`btn-custom background-accent700 h-28 w-[30%] rounded-lg`}
            >
              <p className={'responsive-text-small text-white'}>{val.title}</p>
            </button>
          )
        })}
      </div>

      <p className='mt-20 italic'>Version {VERSION_APP}</p>
    </div>
  )
}

export default Settings

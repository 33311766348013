import { updateDeviceCode } from '@/src/apis/'
import { Footer, Header } from '@/src/components'
import { APP_INFO } from '@/src/constants'
import { IAppInfoLocalStorage } from '@/src/types'
import { isValidatePaymeCode, showToast, useLocalStorage } from '@/src/utils'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Keyboard } from '../components'

const DeviceCode: React.FC = () => {
  const { t } = useTranslation()

  const divRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const [paymeCode, setPaymeCode] = useState<string>(appInfo?.paymeCode ?? '')

  const onNext = async () => {
    if (!isValidatePaymeCode(paymeCode)) {
      showToast('warn', t('toast.invalidPaymeCode'))
      divRef?.current?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      divRef?.current?.classList.remove('animate-shake')
      return
    }

    await updateDeviceCode({
      device_code: appInfo?.deviceCode,
      payme_user_name: paymeCode
    })

    setAppInfo({
      ...appInfo,
      paymeCode: paymeCode
    } as IAppInfoLocalStorage)
    navigate(-1)
    showToast('success', t('toast.paymeCodeUpdated'))
  }

  return (
    <div className='layout pt-40'>
      <Header title='settings.paymeCode' />
      <Keyboard
        type={'payme'}
        data={paymeCode}
        setData={setPaymeCode}
        divRef={divRef}
        title={'settings.inputPaymeCode'}
        placeHolder={'settings.enterPaymeCode'}
      />
      <Footer onNext={onNext} nextTitle={'confirm'} />
    </div>
  )
}

export default DeviceCode

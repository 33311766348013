export const SETTINGS = [
  {
    id: 0,
    title: 'DEVICE CODE',
    key: 'deviceCode',
    link: '/settings-device-code'
  },
  {
    id: 1,
    title: 'LOCATIONS',
    key: 'locations',
    link: '/settings-location'
  },
  {
    id: 2,
    title: 'PAYME CODE',
    key: 'paymeCode',
    link: '/settings-payme'
  },
  {
    id: 3,
    title: 'HiGD',
    key: 'higd',
    link: '/settings-higd'
  }
]

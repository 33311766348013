import { useNavigate } from 'react-router-dom'
import { Footer, Header, KeyboardEmail } from '@/src/components'
const LoginEmail: React.FC = () => {
  const navigate = useNavigate()

  const onNext = () => {
    navigate('/shop')
  }
  return (
    <div className='layout pt-40'>
      <Header title='Email Login' />
      <KeyboardEmail />
      <Footer onNext={onNext} />
    </div>
  )
}

export default LoginEmail

export const KEY_BOARD_TEXT_TYPE_1 = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'br',
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'u',
  'i',
  'o',
  'p',
  'br',
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'br',
  'caps',
  'z',
  'x',
  'c',
  'v',
  'b',
  'n',
  'm',
  'backspace',
  'br',
  'ă',
  'â',
  'ê',
  'ô',
  'ơ',
  'ư',
  'đ',
  'br',
  '̀',
  '́',
  '̉',
  '̃',
  '̣',
  'br',
  '@',
  '.',
  '_',
  '!?#',
  'br',
  'reset',
  'space'
]

export const KEY_BOARD_TEXT_TYPE_2 = [
  '~',
  '/',
  '$',
  '#',
  '(',
  ')',
  '+',
  '&',
  '`',
  '|',
  'br',
  //
  '%',
  '^',
  '=',
  ',',
  '?',
  '!',
  "'",
  '-',
  'br',
  //
  'textformat',
  '@',
  '.',
  '_',
  'backspace',
  'br',
  //
  'reset',
  'space'
]

export const KEY_BOARD_TEXT_TYPE_DEVICE_CODE = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'br',
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'u',
  'i',
  'o',
  'p',
  'br',
  //
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'br',
  //
  'z',
  'x',
  'c',
  'v',
  'b',
  'n',
  'm',
  //
  'br',
  'reset',
  'backspace'
]

export const KEY_BOARD_TEXT_TYPE_PAYME = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'br',
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'u',
  'i',
  'o',
  'p',
  'br',
  //
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
  'j',
  'k',
  'l',
  'br',
  //
  'caps',
  'z',
  'x',
  'c',
  'v',
  'b',
  'n',
  'm',
  //
  'br',
  'reset',
  'backspace'
]

export const KEY_BOARD_TEXT_TYPE_IP = [
  '1',
  '2',
  '3',
  'br',
  '4',
  '5',
  '6',
  'br',
  '7',
  '8',
  '9',
  'br',
  '.',
  '0',
  ':',
  'br',
  'reset',
  'backspace'
]

export const KEY_BOARD_NUMBER = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'reset', '0', 'backspace']

import { TOKEN } from '@/src/constants'
import { IGetApiOptions, IPostApiOptions } from '@/src/types'
import axios, { AxiosResponse } from 'axios'

const URL_SHOP = import.meta.env.VITE_APP_DOMAINBE as string

const createHeaders = (includeAuthorization: boolean): Record<string, string> | undefined => {
  const contentType = { 'Content-Type': 'application/json' }
  if (!includeAuthorization) {
    return contentType
  }

  const token = window.localStorage.getItem(TOKEN)
  const authorizationToken = token ? JSON.parse(token) : ''
  const authorizationHeader = { Authorization: `Bearer ${authorizationToken}` }

  return { ...contentType, ...authorizationHeader }
}

const handleResponse = (response: AxiosResponse) => {
  const { status, data } = response
  const isSuccessStatus = status === 200 || status === 201 || !data?.is_error
  return isSuccessStatus ? data : false
}

const handleError = (error: any): boolean => {
  console.log(error)
  return false
}

// METHODS

const GET_API = async ({
  endpoint,
  isIncludeAuthorization = true,
  isEndpointShop = true
}: IGetApiOptions): Promise<any> => {
  try {
    const headers = createHeaders(isIncludeAuthorization)

    const response = await axios.get(`${isEndpointShop ? `${URL_SHOP}/${endpoint}` : endpoint}`, { headers })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

const POST_API = async ({
  endpoint,
  data,
  isIncludeAuthorization = true,
  isEndpointShop = true
}: IPostApiOptions): Promise<any> => {
  try {
    const headers = createHeaders(isIncludeAuthorization)
    const response = await axios.post(`${isEndpointShop ? `${URL_SHOP}/${endpoint}` : endpoint}`, data, { headers })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

const PUT_API = async ({ endpoint, data, isIncludeAuthorization = true }: IPostApiOptions): Promise<any> => {
  try {
    const headers = createHeaders(isIncludeAuthorization)
    const response = await axios.put(`${URL_SHOP}/${endpoint}`, data, { headers })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

export { GET_API, POST_API, PUT_API }

import { Footer, Header } from '@/src/components'
import { KEY_BOARD_NUMBER, PHONE_REGEX } from '@/src/constants'
import { showToast } from '@/src/utils'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUser } from '@/src/context'
const LoginPhone: React.FC = () => {
  const { t } = useTranslation()

  const { setPhone } = useUser()
  const [phone, _setPhone] = useState<string>('')
  const divRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  const isValidPhoneNumber = async () => {
    const isValid = PHONE_REGEX.test(phone)

    if (!isValid) {
      divRef?.current?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      divRef?.current?.classList.remove('animate-shake')
      showToast('warn', t('toast.invalidPhoneNumber'))
    }
    return isValid
  }

  const onNext = async () => {
    // if (!phone) {
    //   navigate('/shop')
    //   return
    // }
    if (!(await isValidPhoneNumber())) return

    navigate('/shop')
    setPhone(phone)
  }

  const nextTitle = useMemo(() => {
    return phone ? 'next' : 'skip'
  }, [phone])

  return (
    <div className='layout pt-40'>
      <Header title='loginPhone.member' />

      <div className={'w-3/5 flex flex-col gap-4 mt-10'}>
        <p className={'responsive-text-small'}>{t('loginPhone.inputPhoneNumber')}</p>
        <KeyboardNumber phone={phone} setPhone={_setPhone} divRef={divRef} />
      </div>
      <Footer onNext={onNext} nextTitle={'next'} />
    </div>
  )
}

//viewChildren

const KeyboardNumber: React.FC<any> = ({ phone, setPhone, divRef }) => {
  const { t } = useTranslation()

  const onClick = (val: string) => {
    if (val === 'backspace') {
      setPhone(phone.slice(0, -1))
    } else if (val === 'reset') {
      setPhone('')
    } else {
      if (phone?.length > 30) return
      setPhone(phone + val)
    }
  }

  const isBold = (val: string) => {
    let temp = false
    if (val === 'reset' || val === 'backspace') {
      temp = true
    }

    return temp
  }

  return (
    <>
      <div
        ref={divRef}
        className={
          'w-full py-4 px-2.5 border-2 border-blue-500 focus:ring-blue-500 focus:border-blue-500 rounded-lg flex items-center mt-4'
        }
      >
        {phone ? (
          <p className={'responsive-text-small'}>{phone}</p>
        ) : (
          <p className={'text-[#9ca3af] responsive-text-small'}>{t('loginPhone.enterMobileNumber')}</p>
        )}
      </div>
      <div className={'flex items-center justify-center	mt-10'}>
        <div className='w-[18rem] flex flex-row flex-wrap	justify-center items-center'>
          {KEY_BOARD_NUMBER?.map((val, index) => {
            return (
              <button
                onClick={() => onClick(val)}
                key={index}
                type='button'
                className={`
                  ${isBold(val) ? 'background-accent100' : 'bg-[#F6F7FB]'} 
                  w-[5rem] m-1.5 flex justify-center items-center h-[45px] rounded-[40px] btn-custom responsive-text-small`}
              >
                {val === 'backspace' && <i className='material-icons'>backspace</i>}
                {val === 'reset' && <i className='material-icons'>clear</i>}
                {val !== 'backspace' && val !== 'reset' && val}
              </button>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default LoginPhone

import { getLogin, getToken } from '@/src/apis/auth'
import { Button, Header, Modal } from '@/src/components'
import { TOKEN } from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { useLocalStorage } from '@/src/utils'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
const Admin: React.FC = () => {
  const { onLoading } = useApp()

  const [token, setToken] = useLocalStorage<IAppInfoLocalStorage | string>(TOKEN, '')
  const [code, setCode] = useState<string>('')

  const [isModal, setIsModal] = useState<boolean>(false)
  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    // Handle the authentication callback logic here
    const query = new URLSearchParams(location.search)
    const code = query.get('code')
    if (code) setCode(code)
  }, [location.search])

  useEffect(() => {
    if (code) onGetToken(code)
  }, [code])

  const onAuthenticated = async () => {
    onLoading(true)
    const res = await getLogin()
    if (!res?.is_error) window.location.href = res?.data?.loginUrl
    onLoading(false)
  }

  const onGetToken = async (code: any) => {
    onLoading(true)
    const res = await getToken(code)
    if (!res?.is_error) {
      setToken(res?.data?.sessionToken)
      navigate('/home')
    } else {
      setIsModal(true)
    }
    onLoading(false)
  }

  return (
    <div style={{ height: 'calc(100vh - 7rem)' }} className='layout'>
      <Header title='admin.login' isBack={false} />
      <Button onClick={() => onAuthenticated()} title='admin.login' />
      <Modal
        title={'Failed to retrieve token. Please check your account and try again.'}
        isModal={isModal}
        txtRight={'Try again !'}
        onActionRight={() => setIsModal(!isModal)}
      />
    </div>
  )
}

export default Admin

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../index'

interface IModal {
  title: string
  isModal: boolean
  onActionRight?: () => void
  onActionLeft?: () => void
  txtRight?: string
  txtLeft?: string
}

const Modal: React.FC<IModal> = ({ title, isModal, txtRight, txtLeft, onActionRight, onActionLeft }) => {
  return (
    <>
      {isModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[200] '>
            <div className='relative w-auto mx-40 '>
              {/*content*/}
              <div className='rounded-lg shadow-lg relative w-full bg-white p-8'>
                <Body title={title} />
                <Footer
                  onActionRight={onActionRight}
                  onActionLeft={onActionLeft}
                  txtRight={txtRight}
                  txtLeft={txtLeft}
                />
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-[100] bg-black'></div>
        </>
      ) : null}
    </>
  )
}

//viewChildren
const Body: React.FC<any> = ({ title }) => {
  const { t } = useTranslation()

  return <p className='my-4 text-blueGray-500 text-lg leading-relaxed text-center responsive-text'>{t(title)}</p>
}

const Footer: React.FC<any> = ({ onActionLeft, onActionRight, txtRight, txtLeft }) => {
  return (
    <div className={`flex items-center mt-8 gap-8 justify-center`}>
      {onActionLeft && (
        <Button
          onClick={() => onActionLeft(false)}
          className='w-[200px] bg-white border-2 border-blue-500 text-blue-400'
          title={txtLeft || 'Cancel'}
          isTextWhite={false}
        />
      )}

      {onActionRight && <Button onClick={() => onActionRight(false)} className='w-[200px]' title={txtRight || 'OK'} />}
    </div>
  )
}

export default Modal

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import vi from './languages/vi.json';
import en from './languages/en.json';

export const defaultNS = 'translation'; // Default namespace

i18next
  .use(initReactI18next) // Passes i18next instance to react-i18next
  .init({
    lng: 'vi', // Set Vietnamese as the default language
    fallbackLng: 'en', // Fallback to English if a translation is missing in Vietnamese
    debug: true, // Enable debugging in development
    defaultNS, // Set the default namespace
    resources: {
      en: {
        translation: en, // Load the English translations
      },
      vi: {
        translation: vi, // Load the Vietnamese translations
      },
    },
  });

export default i18next;
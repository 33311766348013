import { addCart, filterByBarcode, getCart, updateCart } from '@/src/apis'
import { shop } from '@/src/assets'; // Adjust the path as needed
import { Footer, Header, Modal } from '@/src/components'
import { APP_INFO, ID_CART } from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { showToast, useLocalStorage } from '@/src/utils'
import scanner from '@/src/utils/rfid'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Cart } from './components'
const TIME_INTERVAL = 1500

const Shop: React.FC = () => {
  const [isModal, setIsModal] = useState<boolean>(false)

  const { t } = useTranslation()

  const { onLoading, onSetCart, cart, RFID } = useApp()
  const [isInvoice, setIsInvoice] = useState<boolean>(false)

  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)

  const intervalRef = useRef<NodeJS.Timeout | null>(null) // Store interval ID
  const { rfid } = appInfo ?? {}

  const navigate = useNavigate()

  // Hàm xử lý sự kiện RFID_ITEM
  const onListenEvent = async (eventData: any) => {
    if (!eventData) return

    for (const e of eventData) {
      const isDuplicate = RFID.some((item: any) => item.tag === e.tag)

      if (!isDuplicate) {
        // Nếu không trùng lặp, thêm eventData vào ARRAY_ITEM
        RFID.push(e)

        if (!cart && RFID?.length <= 1) {
          await onAddCart(e)
        } else {
          await onUpdateCart(e)
        }
      }
    }
  }

  const onUpdateCart = async (data: any) => {
    let product = await filterByBarcode([data.barcode])
    if (!product) return

    let quantity = 1

    let variant = product?.data?.data?.data[0]?.variants?.find((item: any) => item.barcode === data.barcode)
    let itemExist = cart?.lineItems?.find((item: any) => item?.variantId === variant?.id)

    if (itemExist) quantity = itemExist?.quantity + 1

    let payload = {
      lineItems: [
        {
          variantId: variant?.id,
          quantity
        }
      ]
    }
    let newCart = await getCart()

    let res = await updateCart(newCart?.data?.id, payload)

    if (res) {
      await onSetCart()
    }
  }
  const onAddCart = async (data: any) => {
    let product = await filterByBarcode([data.barcode])

    if (!product) return

    let variant = product?.data?.data?.data[0]?.variants?.find((item: any) => item.barcode === data.barcode)

    let payload = {
      lineItems: [
        {
          variantId: variant?.id,
          quantity: 1
        }
      ],
      attributes: [
        {
          key: 'x-haravan-device',
          value: appInfo?.deviceCode || ''
        }
      ]
    }
    onLoading(true)
    let res = await addCart(payload)
    onLoading(false)

    if (res) {
      await setIdCart(res?.data?.id)
      await onSetCart()
    }
  }

  useEffect(() => {
    // PREVENT CALL API 2 TIMES IN STRICTMODE
    intervalRef.current = setInterval(() => {
      scanner
        .connectAndReadTags(rfid?.vendorId, rfid?.productId)
        .then((tags: any) => {
          onListenEvent(tags)
        })
        .catch((err: any) => {
          console.log(err)
          showToast('warn', err.message === `can't connect to device` ? t('toast.disconnectRFID') : err.message)
        })
    }, TIME_INTERVAL)

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const onNext = () => {
    if (!cart) return

    if (isInvoice) {
      navigate('/payment-invoice')
    } else {
      setIsModal(true)
    }
  }

  const onConfirmCart = async () => {
    setIsModal(!isModal)
    navigate('/payment-method')
  }

  return (
    <div className='layout pb-40'>
      <Header title='shop.addItems' />
      {cart?.lineItems?.length > 0 ? (
        <Cart isInvoice={isInvoice} setIsInvoice={setIsInvoice} />
      ) : (
        <>
          <p className={'responsive-text-small mt-12 text-center px-4 text-black mt-20'}>
            {t('shop.placeItemsOrBasket')}
          </p>
          <img style={{ height: '30rem' }} src={shop} alt='img' />
        </>
      )}

      <Modal
        title={'shop.confirmOrder'}
        isModal={isModal}
        txtLeft={'back'}
        onActionLeft={() => setIsModal(!isModal)}
        txtRight={'confirm'}
        onActionRight={onConfirmCart}
      />

      <Footer isNext={cart?.lineItems?.length > 0 ? true : false} onNext={onNext} />
    </div>
  )
}

export default Shop
